import {makeStyles} from "@material-ui/core/styles";
import {fade} from "@material-ui/core";
import {isMobile} from "react-device-detect";

const createAlertCommonStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        height: '60px',
        alignItems: 'center',
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.2),
            cursor: 'pointer',
        },
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: 'rgb(50,50,50)',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1.5),
    },
    cardContents: {
        display: 'flex',
        flexFlow: 'column',
        textAlign: 'left'
    },
    cardTitle: {
        color: theme.palette.primary.main,
        fontSize: `${isMobile ? '14px' : '15px'}`,
        textTransform: 'uppercase',
        fontWeight: 800,
    },
    cardData: {
        fontSize: '14px',
        color: fade(theme.palette.common.white, 0.7),
    },
    loadingSpinner: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))

export default createAlertCommonStyles;