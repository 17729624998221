import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import {RootState} from "../../redux";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AlertType, CreateAlertEventActionTypes, DefaultAlertType} from "../../redux/create_alert/types";
import {createAlert, showCreateAlertDialog} from "../../redux/create_alert/actions";
import {fade, makeStyles} from "@material-ui/core/styles";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        showCreateAlertDialog: (shouldShow: boolean) => dispatch(showCreateAlertDialog(shouldShow)),
        createAlert: (oddsAmount: number, alertType: string) => dispatch(createAlert(oddsAmount, alertType)),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type CreateAlertDialogComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '23px',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 800,
    },
    content: {
        maxWidth: '400px'
    },
    description: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2.5),
        color: fade(theme.palette.common.white, 0.8),
        fontSize: '16px',
        wordWrap: 'break-word'
    },
    formControl: {
        minWidth: '175px',
        marginRight: theme.spacing(2),
    },
    inputFields: {
        display: 'flex',
        alignItems: 'center',
    },
    runnerName: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    bold: {
        fontWeight: 'bold'
    },
    actions: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
    },
    back: {
        fontWeight: 'bold',
        color: '#a6d7ff',
    },
    lay: {
        fontWeight: 'bold',
        color: '#fac9d1',
    },
    oddsInput: {
        userSelect: 'none'
    }
}));

const CreateAlertDialog: React.FC<CreateAlertDialogComponentProps> = (props) => {
    const {createAlertState, showCreateAlertDialog, createAlert} = props;

    const classes = useStyles();

    const [oddsInput, setOddsInput] = useState("");

    const [userHasModifiedInput, setUserHasModifiedInput] = useState(false);

    const [alertType, setAlertType] = useState(AlertType.LowerThanBackPrice.toString())

    const createButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (oddsInput.startsWith('.') || oddsInput.startsWith('0')) {
            setOddsInput('');
        }
    }, [oddsInput])

    useEffect(() => {
        if (!userHasModifiedInput && createAlertState.runnerSelection) {
            let defaultOdds: number;
            let defaultAlertType: string;

            switch (createAlertState.showCreateAlertDialog?.defaultType) {
                case DefaultAlertType.BACK:
                    defaultOdds = createAlertState.runnerSelection!.availableToBack!.price!;
                    defaultAlertType = AlertType.LowerThanBackPrice.toString();
                    break;
                case DefaultAlertType.LAY:
                    defaultOdds = createAlertState.runnerSelection!.availableToLay!.price!;
                    defaultAlertType = AlertType.LowerThanLayPrice.toString();
                    break;
            }

            setOddsInput(defaultOdds!.toString())
            setAlertType(defaultAlertType!)
        }
    }, [createAlertState.runnerSelection, createAlertState.showCreateAlertDialog, userHasModifiedInput])

    useEffect(() => {
        return () => {
            setOddsInput('');
            setUserHasModifiedInput(false);
        }
    }, [])

    const handleClose = () => {
        showCreateAlertDialog(false);

        setTimeout(() => {
            setUserHasModifiedInput(false);
        }, 500)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOddsInput(e.currentTarget.value)
    }

    const handleKeyDown = (e: any) => {
        if (createButtonRef && createButtonRef.current && e.key === 'Enter') {
            createButtonRef.current.click();
        }

        setUserHasModifiedInput(true);

        const re = /^[0-9\b]+$/;

        if (!(e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Tab')) {
            if (!((e.key === '.' && oddsInput.indexOf('.') === -1) || re.test(e.key))) {
                e.preventDefault();
            } else if (oddsInput.length === 0 && (e.key === '.' || e.key === '0')) {
                e.preventDefault();
            } else if (oddsInput.length === 5 && e.key === '.') {
                e.preventDefault();
            }
        }
    }

    const oddsDescription = (): JSX.Element => {
        if (oddsInput) {
            if (alertType === AlertType.LowerThanLastPriceTraded || alertType === AlertType.HigherThanLastPriceTraded) {
                return (
                    <>
                        You will be alerted
                        when the last price traded
                        for <span className={classes.runnerName}>{createAlertState.runnerSelection?.runnerName}</span> is {alertType.startsWith('Lower') ? 'lower' : 'higher'} than
                        or equal
                        to <span className={classes.bold}>{oddsInput}</span>.
                    </>
                )
            } else {
                return (
                    <>
                        You will be alerted
                        when <span className={classes.runnerName}>{createAlertState.runnerSelection?.runnerName}</span> is {alertType.startsWith('Lower') ? 'lower' : 'higher'} than
                        or equal
                        to <span className={classes.bold}>{`${oddsInput} (${((1 / parseFloat(oddsInput)) * 100).toFixed(2)}%)`}</span> to <span className={alertType.indexOf('Back') !== -1 ? classes.back : classes.lay}>{alertType.indexOf('Back') !== -1 ? 'back' : 'lay'}</span>.
                    </>
                )
            }
        } else {
            return (
                <>
                    Please enter an odds amount.
                </>
            )
        }
    }

    const handleAlertTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAlertType(event.target.value as string)
    }

    const handleCreateAlert = () => {
        createAlert(parseFloat(oddsInput), alertType);
        handleClose();
    }

    return (
        <div>
            <Dialog open={createAlertState.showCreateAlertDialog!.shouldShow}
                    onClose={handleClose}>
                <div className={classes.root}>
                    <div className={classes.title}>New Alert</div>
                    <div className={classes.content}>
                        <div className={classes.description}>
                            {oddsDescription()}
                        </div>
                        <div className={classes.inputFields}>
                            <FormControl variant="outlined"
                                         margin="dense"
                                         className={classes.formControl}>
                                <InputLabel id="alert-type-label">Alert Type</InputLabel>
                                <Select
                                    defaultValue={createAlertState.showCreateAlertDialog?.defaultType === DefaultAlertType.BACK ? 'LowerThanBackPrice' : 'LowerThanLayPrice'}
                                    value={alertType}
                                    onChange={handleAlertTypeChange}
                                    labelId="alert-type-label"
                                    id="alert-type-outlined"
                                    label="Alert Type"
                                >
                                    <MenuItem value={AlertType.LowerThanBackPrice}>Back Lower Than</MenuItem>
                                    <MenuItem value={AlertType.HigherThanBackPrice}>Back Higher Than</MenuItem>
                                    <MenuItem value={AlertType.LowerThanLayPrice}>Lay Lower Than</MenuItem>
                                    <MenuItem value={AlertType.HigherThanLayPrice}>Lay Higher Than</MenuItem>
                                    <MenuItem value={AlertType.LowerThanLastPriceTraded}>Last Price Traded Lower
                                        Than</MenuItem>
                                    <MenuItem value={AlertType.HigherThanLastPriceTraded}>Last Price Traded Higher
                                        Than</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                classes={{
                                    root: classes.oddsInput,
                                }}
                                autoFocus
                                value={oddsInput}
                                onChange={handleChange}
                                onKeyDownCapture={handleKeyDown}
                                onDrop={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                inputProps={{maxLength: 6}}
                                variant={"outlined"}
                                id="Odds"
                                label="Odds"
                                type="text"
                                margin="dense"
                                required
                            />
                        </div>
                    </div>
                    <div className={classes.actions}>
                        <Button onClick={handleClose}
                                variant={"outlined"}
                                color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleCreateAlert()}
                                ref={createButtonRef}
                                variant={"contained"}
                                style={{
                                    marginLeft: '10px'
                                }}
                                disabled={oddsInput.length === 0}
                                color="primary">
                            Create
                        </Button>
                    </div>
                </div>

            </Dialog>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateAlertDialog);
