import {loginReducer} from "./login/reducers";
import {combineReducers} from "redux";
import {connectRouter, RouterState} from 'connected-react-router'
import {LoginState} from "./login/types";
import {notificationsReducer} from "./notifications/reducers";
import {NotificationsState} from "./notifications/types";
import {createAlertReducer} from "./create_alert/reducers";
import {CreateAlertState} from "./create_alert/types";
import {ManageAlertsState} from "./manage_alerts/types";
import {manageAlertsReducer} from "./manage_alerts/reducers";

export const rootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    notifications: notificationsReducer,
    createAlert: createAlertReducer,
    manageAlerts: manageAlertsReducer,
});

export interface RootState {
    router: RouterState,
    login: LoginState,
    notifications: NotificationsState,
    createAlert: CreateAlertState,
    manageAlerts: ManageAlertsState
}
