import {ManageAlertsEventActionTypes, ManageAlertsEventTypes, ManageAlertsState} from "./types";

const initialState: ManageAlertsState = {
    webSocket: undefined,
    myAlertsList: [],
    errors: []
};

export function manageAlertsReducer(
    state = initialState,
    action: ManageAlertsEventActionTypes
): ManageAlertsState {
    switch (action.type) {
        case ManageAlertsEventTypes.STARTED: {
            return {
                ...state,
            };
        }
        case ManageAlertsEventTypes.FAILURE: {
            return {
                ...state,
                errors: [...state.errors!, action.meta.error],
            };
        }
        case ManageAlertsEventTypes.SUCCESS: {
            return {
                ...state,
            };
        }
        case ManageAlertsEventTypes.STORE_ALERTS: {
            return {
                ...state,
                myAlertsList: action.payload
            }
        }
        case ManageAlertsEventTypes.SAVE_WS: {
            return {
                ...state,
                webSocket: action.payload
            }
        }
        default:
            return state
    }
}
