import {NotificationEventActionTypes, NotificationTypes} from "./types";

export const notificationSuccess = (message: string): NotificationEventActionTypes => ({
    type: NotificationTypes.SUCCESS,
    payload: message
});

export const notificationError = (message: string): NotificationEventActionTypes => ({
    type: NotificationTypes.ERROR,
    payload: message
});
