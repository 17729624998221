import React from "react";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Color} from "@material-ui/lab/Alert";
import {NotificationData} from "../redux/notifications/types";
import {makeStyles} from "@material-ui/core/styles";

interface CustomAlertProps {
    key: string | number,
    alertDataString: string,
}

const useStyles = makeStyles(() => ({
    alert: {
        width: '500px',
        textAlign: 'left'
    },
}));

const CustomAlert: React.FC<CustomAlertProps> = React.forwardRef((props, ref) => {
    const classes = useStyles();

    const {key, alertDataString} = props;

    const alertData: NotificationData = JSON.parse(alertDataString);

    return (
        <Alert ref={ref}
               className={classes.alert}
               key={key}
               severity={alertData.type as Color}>
            <AlertTitle>{alertData.type.slice(0, 1).toUpperCase() + alertData.type.slice(1)}</AlertTitle>
            {alertData.message}
        </Alert>
    )
});

export default CustomAlert;