import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../redux";
import {CreateAlertCurrentViewTypes, CreateAlertEventActionTypes} from "../../redux/create_alert/types";
import {
    createAlertSetEventType,
    createAlertSetPageTitle,
    createAlertSetView,
    getEventTypes
} from "../../redux/create_alert/actions";
import React, {useEffect} from "react";
import {Avatar, Card, GridList, GridListTile, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import {Motion, spring} from "react-motion";
import createAlertCommonStyles from "./CommonStyles";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        getEventTypes: () => dispatch(getEventTypes()),
        createAlertSetEventType: (eventTypeId: number) => dispatch(createAlertSetEventType(eventTypeId)),
        createAlertSetView: (newView: CreateAlertCurrentViewTypes) => dispatch(createAlertSetView(newView)),
        createAlertSetPageTitle: (title: string) => dispatch(createAlertSetPageTitle(title)),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type SelectEventTypeComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SelectEventTypeComponent: React.FC<SelectEventTypeComponentProps> = (props) => {
    const {createAlertState, getEventTypes, createAlertSetEventType, createAlertSetView, createAlertSetPageTitle} = props;

    const theme = useTheme();
    const classes = createAlertCommonStyles();

    useEffect(() => {
        getEventTypes();
    }, [getEventTypes]);

    const listEvents = (eventTypeId: number, eventTypeName: string) => {
        createAlertSetEventType(eventTypeId);
        createAlertSetView(CreateAlertCurrentViewTypes.SELECT_EVENT);
        createAlertSetPageTitle(eventTypeName);
    }

    if (createAlertState.isLoading) {
        return (
            <LoadingSpinner className={classes.loadingSpinner}
                            color={theme.palette.primary.main}/>
        );
    } else {
        return (
            <Motion key={CreateAlertCurrentViewTypes.SELECT_EVENT_TYPE}
                    defaultStyle={{opacity: 0}}
                    style={{opacity: spring(1)}}>
                {interpolatedStyle =>
                    <GridList
                        style={{opacity: interpolatedStyle.opacity}}
                        cellHeight='auto'
                        cols={1}
                        spacing={10}>
                        {createAlertState.eventTypes!.map((eventType, i) => (
                            <GridListTile key={i}
                                          onClick={() => listEvents(eventType.eventType.id, eventType.eventType.name)}>
                                <Card key={i}
                                      elevation={0}
                                      className={classes.card}
                                >
                                    <Avatar variant="rounded"
                                            className={classes.avatar}>
                                        {eventType.eventType.name.slice(0, 1)}
                                    </Avatar>
                                    <div className={classes.cardContents}>
                                        <div className={classes.cardTitle}>
                                            {eventType.eventType.name}
                                        </div>
                                        <div className={classes.cardData}>
                                            {eventType.marketCount} Market{eventType.marketCount !== 1 ? "s" : ""} Available
                                        </div>
                                    </div>
                                </Card>
                            </GridListTile>
                        ))}
                    </GridList>
                }
            </Motion>
        )
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectEventTypeComponent);
