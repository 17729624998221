import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Redirect, Route, Switch} from "react-router-dom";
import {ConnectedRouter} from "connected-react-router";
import {history} from "../redux/store"
import LoginComponent from "./LoginComponent";
import AlertComponent from "./NotificationDisplayer";
import {SnackbarProvider} from "notistack";
import CustomAlert from "./CustomAlert";
import HomeComponent from "./HomeComponent";
import PrivateRoute from "./PrivateRoute";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
        height: '100vh'
    },
    toolbar: theme.mixins.toolbar,
}));

const RootComponent: React.FC = () => {
    const classes = useStyles();

    return (
        <ConnectedRouter history={history}>
            <SnackbarProvider maxSnack={3}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                              }}
                              content={(key, alertDataString: string) => (
                                  <CustomAlert key={key}
                                               alertDataString={alertDataString}/>
                              )}
            >
                <div className={classes.root}>
                    <main className={classes.content}>
                        <AlertComponent/>
                        <Switch>
                            <Route path="/login">
                                <LoginComponent/>
                            </Route>
                            <PrivateRoute path="/home">
                                <HomeComponent/>
                            </PrivateRoute>
                            <Redirect from="*"
                                      to="/login"/>
                        </Switch>

                    </main>
                </div>
            </SnackbarProvider>
        </ConnectedRouter>
    )
};

export default RootComponent;
