import React, {useEffect} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RootState} from "../redux";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {Motion, spring} from "react-motion";
import {ManageAlertsEventActionTypes} from "../redux/manage_alerts/types";
import {connectManageAlerts} from "../redux/manage_alerts/actions";
import {AutoSizer, List} from "react-virtualized";
import MyAlertCard from "./MyAlertCard";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, ManageAlertsEventActionTypes>) => {
    return {
        connectManageAlerts: () => dispatch(connectManageAlerts()),
    }
};

const mapStateToProps = (state: RootState) => ({
    manageAlertsState: state.manageAlerts,
});

type ManageAlertsListComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const useStyles = makeStyles(theme => ({
    manageAlertsListParent: {
        height: '100%',
        maxHeight: 'calc(100vh - 150px)',
    },
    pagesParent: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: theme.spacing(1)
    },
    alternateItem: {
        display: 'flex',
        color: '#5f5f5f',
        fontSize: '18px',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loadingSpinner: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

const ManageAlertsList: React.FC<ManageAlertsListComponentProps> = (props) => {
    const {manageAlertsState, connectManageAlerts} = props;

    const classes = useStyles();

    useEffect(() => {
        connectManageAlerts();
    }, [connectManageAlerts])

    const cellRenderer = (props: any) => {
        const {index, key, style} = props;

        const alert = manageAlertsState.myAlertsList![index];

        if (alert) {
            return (
                <div style={style}
                     key={key}
                >
                    <MyAlertCard alert={alert}/>
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <Motion key={'ManageAlertsList'}
                defaultStyle={{opacity: 0}}
                style={{opacity: spring(1)}}>
            {interpolatedStyle =>
                <div className={classes.manageAlertsListParent}>
                    <div className={classes.pagesParent}
                         style={{opacity: interpolatedStyle.opacity}}>
                        {manageAlertsState.myAlertsList!.length > 0 ?
                            <AutoSizer>
                                {({height, width}) => (
                                    <List
                                        style={{opacity: interpolatedStyle.opacity}}
                                        rowRenderer={cellRenderer}
                                        rowCount={manageAlertsState.myAlertsList!.length}
                                        rowHeight={100}
                                        height={height}
                                        width={width}
                                        overscanRowCount={10}
                                    />
                                )}
                            </AutoSizer>
                            :
                            <Typography className={classes.alternateItem}>You currently have no alerts.</Typography>
                        }
                    </div>
                </div>
            }
        </Motion>
    )
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManageAlertsList);
