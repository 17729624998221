export interface CreateAlertState {
    allViews?: CreateAlertCurrentViewTypes[]
    eventTypes?: EventType[]
    events?: Event[]
    markets?: Market[]
    runnerOddsData?: RunnerOddsData
    isLoading?: boolean
    errors?: string[]
    eventTypeId?: number
    eventId?: number
    marketCount?: number
    marketId?: string
    title?: string[]
    showCreateAlertDialog?: {
        shouldShow: boolean,
        defaultType: DefaultAlertType
    }
    runnerSelection?: RunnerOdds
}

export enum CreateAlertCurrentViewTypes {
    SELECT_EVENT_TYPE = 'CREATE_ALERT_SELECT_EVENT_TYPE',
    SELECT_EVENT = 'CREATE_ALERT_SELECT_EVENT',
    SELECT_MARKET = 'CREATE_ALERT_SELECT_MARKET',
    ALL_RUNNER_ODDS = 'CREATE_ALERT_ALL_RUNNER_ODDS',
}

export interface EventType {
    eventType: EventTypeInfo,
    marketCount: number,
}

interface EventTypeInfo {
    id: number,
    name: string,
}

export interface Event {
    event: EventInfo,
    marketCount: number,
}

interface EventInfo {
    id: number,
    name: string,
    countryCode?: string,
    timezone: string,
    openDate: string,
}

export interface Market {
    marketId: string,
    marketName: string,
    totalMatched: number,
}

export interface RunnerOddsData {
    totalMatched: number,
    allRunnerOdds: RunnerOdds[]
}

export interface RunnerOdds {
    marketId: string,
    selectionId: number,
    runnerName: string,
    availableToBack: AvailableToBack,
    availableToLay: AvailableToLay,
}

export interface AvailableToBack {
    price?: number,
    size?: number
}

export interface AvailableToLay {
    price?: number,
    size?: number
}

export enum CreateAlertEventNetworkTypes {
    STARTED = 'CREATE_ALERT_EVENT_STARTED',
    FAILURE = 'CREATE_ALERT_EVENT_FAILURE',
    SUCCESS = 'CREATE_ALERT_EVENT_SUCCESS',
}

interface CreateAlertEventStarted {
    type: CreateAlertEventNetworkTypes.STARTED
}

interface CreateAlertEventFailure {
    type: CreateAlertEventNetworkTypes.FAILURE
    meta: {
        error: string
    }
}

interface CreateAlertEventSuccess {
    type: CreateAlertEventNetworkTypes.SUCCESS,
    payload: CreateAlertState
}

export enum CreateAlertEventModifyViewTypes {
    PREVIOUS_VIEW = 'CREATE_ALERT_PREVIOUS_VIEW',
    SET_VIEW = 'CREATE_ALERT_SET_VIEW',
}

interface PreviousView {
    type: CreateAlertEventModifyViewTypes.PREVIOUS_VIEW
}

interface SetView {
    type: CreateAlertEventModifyViewTypes.SET_VIEW
    payload: CreateAlertCurrentViewTypes
}

export enum CreateAlertEventSetterTypes {
    SET_EVENT_TYPE_ID = 'CREATE_ALERT_SET_EVENT_TYPE_ID',
    SET_EVENT_ID = 'CREATE_ALERT_SET_EVENT_ID',
    SET_MARKET = 'CREATE_ALERT_SET_MARKET',
    SET_PAGE_TITLE = 'CREATE_ALERT_SET_PAGE_TITLE',
    SET_RUNNER_SELECTION = 'CREATE_ALERT_SET_RUNNER_SELECTION',
}

interface CreateAlertSetEventTypeId {
    type: CreateAlertEventSetterTypes.SET_EVENT_TYPE_ID,
    payload: number
}

interface CreateAlertSetEventId {
    type: CreateAlertEventSetterTypes.SET_EVENT_ID,
    payload: {
        eventId: number,
        marketCount: number
    }
}

interface CreateAlertSetMarket {
    type: CreateAlertEventSetterTypes.SET_MARKET,
    payload: string
}

interface CreateAlertSetPageTitle {
    type: CreateAlertEventSetterTypes.SET_PAGE_TITLE,
    payload: string
}

interface CreateAlertSetRunnerSelection {
    type: CreateAlertEventSetterTypes.SET_RUNNER_SELECTION,
    payload: RunnerOdds
}

export enum CreateAlertModifyStateTypes {
    RESET_STATE = 'CREATE_ALERT_RESET_STATE',
    SHOW_DIALOG = 'CREATE_ALERT_SHOW_DIALOG'
}

interface CreateAlertResetState {
    type: CreateAlertModifyStateTypes.RESET_STATE,
}

interface CreateAlertShowDialog {
    type: CreateAlertModifyStateTypes.SHOW_DIALOG,
    payload: {
        shouldShow: boolean,
        defaultType?: DefaultAlertType
    }
}

export enum DefaultAlertType {
    BACK = 'BACK',
    LAY = 'LAY'
}

export enum AlertType {
    LowerThanBackPrice = 'LowerThanBackPrice',
    HigherThanBackPrice = 'HigherThanBackPrice',
    LowerThanLayPrice = 'LowerThanLayPrice',
    HigherThanLayPrice = 'HigherThanLayPrice',
    LowerThanLastPriceTraded = 'LowerThanLastPriceTraded',
    HigherThanLastPriceTraded = 'HigherThanLastPriceTraded',
}

type CreateAlertActionTypes =
    CreateAlertEventStarted
    | CreateAlertEventFailure
    | CreateAlertEventSuccess
    | PreviousView
    | SetView
    | CreateAlertSetEventTypeId
    | CreateAlertSetEventId
    | CreateAlertSetMarket
    | CreateAlertSetPageTitle
    | CreateAlertSetRunnerSelection
    | CreateAlertResetState
    | CreateAlertShowDialog

export type CreateAlertEventActionTypes = CreateAlertActionTypes
