export interface NotificationsState {
    notifications: NotificationData[]
}

export interface NotificationData {
    type: string,
    message: string,
}

export enum NotificationTypes {
    ERROR = 'NOTIFICATION_ERROR',
    SUCCESS = 'NOTIFICATION_SUCCESS',
}

interface NotificationError {
    type: NotificationTypes.ERROR,
    payload: string
}

interface NotificationSuccess {
    type: NotificationTypes.SUCCESS,
    payload: string
}

type NotificationActionTypes = NotificationError | NotificationSuccess;

export type NotificationEventActionTypes = NotificationActionTypes
