import React from "react";
import {Motion, spring} from "react-motion";
import ScaleLoader from "react-spinners/ScaleLoader";

interface LoadingSpinnerProps {
    className?: string,
    color?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
    let {className, color} = props;

    return (
        <Motion defaultStyle={{opacity: 0, scale: 0}}
                style={{opacity: spring(1), scale: spring(1)}}>
            {interpolatedStyle =>
                <div
                    className={className}
                    key='loadingSpinner'
                    style={{
                        opacity: interpolatedStyle.opacity,
                        transform: `scale(${interpolatedStyle.scale})`
                    }}
                >
                    <ScaleLoader color={color}/>
                </div>
            }
        </Motion>
    )
}

export default LoadingSpinner;