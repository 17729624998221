import axios from "axios";
import {Dispatch} from "redux";
import {LoginEventActionTypes, LoginEventTypes, LoginState, RelogEventTypes} from "./types";
import {notificationError} from "../notifications/actions";
import {NotificationEventActionTypes} from "../notifications/types";
import handleError from "../../util";
import {loginStorageKey} from "./reducers";

export const login = (apiToken: string) => (dispatch: Dispatch<LoginEventActionTypes | NotificationEventActionTypes>) => {
    doLogin(dispatch, apiToken, loginEventStarted, loginEventSuccess, loginEventFailure);
};

export const relog = (apiToken: string) => (dispatch: Dispatch<LoginEventActionTypes | NotificationEventActionTypes>) => {
    doLogin(dispatch, apiToken, relogEventStarted, relogEventSuccess, relogEventFailure);
}

function doLogin(dispatch: Dispatch<LoginEventActionTypes | NotificationEventActionTypes>, apiToken: string, startEvent: startEventType, successEvent: successEventType, failureEvent: failureEventType) {
    dispatch(startEvent());

    axios
        .post(`/v1/api/login`, {}, {
            headers: {
                'API-KEY': apiToken,
            }
        })
        .then(res => {
            let userName = res.data.data;

            let newState: LoginState = {
                apiToken: apiToken,
                userName: userName
            }

            localStorage.setItem(loginStorageKey, apiToken);

            return dispatch(successEvent(newState));
        })
        .catch(err => {
            localStorage.removeItem(loginStorageKey);

            err = handleError(err);

            dispatch(notificationError(err));

            return dispatch(failureEvent(err));
        })
}

const loginEventStarted = (): LoginEventActionTypes => ({
    type: LoginEventTypes.STARTED,
});

const relogEventStarted = (): LoginEventActionTypes => ({
    type: RelogEventTypes.STARTED,
});

type startEventType = typeof loginEventStarted | typeof relogEventStarted;

const loginEventSuccess = (loginState: LoginState): LoginEventActionTypes => ({
    type: LoginEventTypes.SUCCESS,
    payload: loginState
});

const relogEventSuccess = (loginState: LoginState): LoginEventActionTypes => ({
    type: RelogEventTypes.SUCCESS,
    payload: loginState
});

type successEventType = typeof loginEventSuccess | typeof relogEventSuccess;

const loginEventFailure = (err: string): LoginEventActionTypes => ({
    type: LoginEventTypes.FAILURE,
    meta: {
        error: err
    }
});

const relogEventFailure = (err: string): LoginEventActionTypes => ({
    type: RelogEventTypes.FAILURE,
    meta: {
        error: err
    }
});

type failureEventType = typeof loginEventFailure | typeof relogEventFailure;
