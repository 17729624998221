import {RootState} from "../redux";
import React from "react";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const mapStateToProps = (state: RootState) => ({
    loginState: state.login,
});

interface PrivateRoutePath {
    children: React.ReactNode,
    path: string
}

type PrivateRouteProps = ReturnType<typeof mapStateToProps> & PrivateRoutePath;

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const {loginState} = props;

    return (
        <Route
            render={({location}) =>
                loginState.isLoggedIn ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export default connect(
    mapStateToProps,
)(PrivateRoute)
