export interface LoginState {
    apiToken: string
    userName: string
    isLoggedIn?: boolean
    isReloggingIn?: boolean
    isLoading?: boolean
    errors?: string[]
}

export enum LoginEventTypes {
    STARTED = 'LOGIN_EVENT_STARTED',
    FAILURE = 'LOGIN_EVENT_FAILURE',
    SUCCESS = 'LOGIN_EVENT_SUCCESS',
}

interface LoginEventStarted {
    type: LoginEventTypes.STARTED
}

interface LoginEventFailure {
    type: LoginEventTypes.FAILURE
    meta: {
        error: string
    }
}

interface LoginEventSuccess {
    type: LoginEventTypes.SUCCESS,
    payload: LoginState
}

export enum RelogEventTypes {
    STARTED = 'RELOG_EVENT_STARTED',
    FAILURE = 'RELOG_EVENT_FAILURE',
    SUCCESS = 'RELOG_EVENT_SUCCESS',
}

interface RelogEventStarted {
    type: RelogEventTypes.STARTED
}

interface RelogEventFailure {
    type: RelogEventTypes.FAILURE
    meta: {
        error: string
    }
}

interface RelogEventSuccess {
    type: RelogEventTypes.SUCCESS,
    payload: LoginState
}

type LoginActionTypes =
    LoginEventStarted
    | LoginEventFailure
    | LoginEventSuccess
    | RelogEventStarted
    | RelogEventFailure
    | RelogEventSuccess

export type LoginEventActionTypes = LoginActionTypes
