import React, {useEffect, useState} from "react";
import {fade, makeStyles} from '@material-ui/core/styles';
import ArrowForward from "@material-ui/icons/ArrowForward";
import InputBase from "@material-ui/core/InputBase";
import {ThunkDispatch} from "redux-thunk";
import {LoginEventActionTypes} from "../redux/login/types";
import {login, relog} from "../redux/login/actions";
import {RootState} from "../redux";
import {Card, IconButton, Typography} from "@material-ui/core";
import {spring, StaggeredMotion} from "react-motion";
import {connect} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {loginStorageKey} from "../redux/login/reducers";
import LoadingSpinner from "./LoadingSpinner";
import {isMobile} from "react-device-detect";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, LoginEventActionTypes>) => {
    return {
        login: (apiToken: string) => dispatch(login(apiToken)),
        relog: (apiToken: string) => dispatch(relog(apiToken)),
    }
};

const mapStateToProps = (state: RootState) => ({
    loginState: state.login,
});

type LoginComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    card: {
        display: 'flex',
        flexFlow: 'column',
        height: '193px',
        maxWidth: `${isMobile && window.outerWidth < 450 ? 'calc(100vw - 65px)' : '391.5px'}`,
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    cardTitle: {
        color: theme.palette.secondary.main,
        fontFamily: 'Raleway',
        fontSize: `${isMobile ? '20px' : '24px'}`,
        fontWeight: 800,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase'
    },
    cardSubTitle: {
        color: fade(theme.palette.secondary.main, 0.45),
        fontFamily: 'Raleway',
        fontSize: '1.75ch',
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        textTransform: 'uppercase'
    },
    keyFieldParent: {
        width: `${isMobile && window.outerWidth < 450 ? 'calc(100vw - 80px)' : '371.5px'}`,
        color: theme.palette.secondary.main
    },
    keyField: {
        borderRadius: theme.spacing(1),
        backgroundColor: fade(theme.palette.secondary.main, 0.10),
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        flexGrow: 1,
    },
    keyFieldInput: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.main,
        fontFamily: 'Raleway',
        fontWeight: 600,
        fontSize: '16px',
    },
    keyFieldLoginButton: {
        color: theme.palette.secondary.main,
        padding: 10,
        display: 'flex',
        outline: 0,
        backgroundColor: fade(theme.palette.secondary.main, 0.125),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.125),
        },
        '&:active': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
    },
    loadingSpinner: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${isMobile ? 'calc(100vw - 65px)' : '391.5px'}`,
    }
}));

const LoginComponent: React.FC<LoginComponentProps> = (props) => {
    const classes = useStyles();

    const {login, relog, loginState} = props;

    const [loginInput, setLoginInput] = useState("");

    const history = useHistory();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        let apiKey = localStorage.getItem(loginStorageKey);

        if (apiKey) {
            relog(apiKey);
        }
    }, [relog])

    useEffect(() => {
        if (loginState.isLoggedIn === true) {
            history.push('/home');
        }
    }, [history, loginState])

    useEffect(() => {
        let queryApiKey = query.get("api-key");

        if (queryApiKey) {
            setLoginInput(queryApiKey);
        }
    }, [query, setLoginInput])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin()
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLoginInput(e.target.value)
    }

    const handleLogin = () => {
        if (loginInput.trim() !== "") {
            login(loginInput);
        }
    }

    if (loginState.isReloggingIn) {
        return null;
    } else {
        return (
            <div className={classes.root}>
                <StaggeredMotion
                    defaultStyles={[
                        {move_amount: -100, opacity: 0},
                        {move_amount: -100, opacity: 0},
                        {move_amount: -100, opacity: 0}
                    ]}
                    styles={prevInterpolatedStyles => prevInterpolatedStyles!.map((_, i) => {
                        return i === 0
                            ? {
                                move_amount: spring(0, {stiffness: 140, damping: 20}),
                                opacity: spring(1, {stiffness: 40, damping: 20})
                            }
                            : {
                                move_amount: spring(prevInterpolatedStyles![i - 1].move_amount, {
                                    stiffness: 100,
                                    damping: 20
                                }),
                                opacity: spring(prevInterpolatedStyles![i - 1].opacity, {
                                    stiffness: 100,
                                    damping: 15
                                })
                            };
                    })}
                >
                    {(cardContentStyles: any[]) =>
                        <Card className={classes.card}
                              variant="elevation">
                            {
                                !loginState.isLoading ?
                                    <>
                                        <Typography
                                            noWrap
                                            className={classes.cardTitle}
                                            style={cardContentStyles[0] && {
                                                transform: `translateY(${cardContentStyles[0].move_amount * 1.5}px)`,
                                                opacity: `${cardContentStyles[0].opacity}`
                                            }}
                                        >
                                            Betting Notify Dashboard
                                        </Typography>
                                        <Typography
                                            noWrap
                                            className={classes.cardSubTitle}
                                            style={cardContentStyles[1] && {
                                                transform: `translateY(${cardContentStyles[1].move_amount * 0.75}px)`,
                                                opacity: `${cardContentStyles[1].opacity}`
                                            }}
                                        >
                                            Please Enter Your Token Below
                                        </Typography>
                                        <div
                                            style={cardContentStyles[2] && {
                                                transform: `translateX(${cardContentStyles[2].move_amount * 10}px)`,
                                                opacity: `${cardContentStyles[2].opacity}`
                                            }}>
                                            <div className={classes.keyFieldParent}>
                                                <div className={classes.keyField}>
                                                    <InputBase
                                                        autoFocus={true}
                                                        classes={{
                                                            input: classes.keyFieldInput,
                                                        }}
                                                        value={loginInput}
                                                        inputProps={{'aria-label': 'token'}}
                                                        fullWidth={true}
                                                        onChange={e => handleInput(e)}
                                                        onKeyDown={e => handleKeyDown(e)}
                                                    />
                                                    <IconButton className={classes.keyFieldLoginButton}
                                                                onClick={() => handleLogin()}
                                                                disableRipple>
                                                        <ArrowForward/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <div className={classes.loadingSpinner}>
                                        <LoadingSpinner/>
                                    </div>
                            }
                        </Card>
                    }
                </StaggeredMotion>
            </div>
        )
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent)
