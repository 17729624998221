import React from "react";
import {RootState} from "../redux";
import {connect} from "react-redux";
import {CreateAlertCurrentViewTypes} from "../redux/create_alert/types";

interface CreateAlertPageProps {
    view: CreateAlertCurrentViewTypes,
    component: React.ComponentType<any>,
}

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type CreateAlertPageComponentProps = ReturnType<typeof mapStateToProps> & CreateAlertPageProps;

const CreateAlertPage: React.FC<CreateAlertPageComponentProps> = (props) => {
    const {createAlertState, view} = props;

    return (createAlertState.allViews![createAlertState.allViews!.length - 1] === view ?
        <props.component/> : null)
}

export default connect(
    mapStateToProps
)(CreateAlertPage);