import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../redux";
import {CreateAlertCurrentViewTypes, CreateAlertEventActionTypes} from "../../redux/create_alert/types";
import {
    createAlertSetEvent,
    createAlertSetPageTitle,
    createAlertSetView,
    getEvents
} from "../../redux/create_alert/actions";
import React, {useEffect} from "react";
import {Avatar, Card, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import {Motion, spring} from "react-motion";
import {AutoSizer, List} from "react-virtualized";
import createAlertCommonStyles from "./CommonStyles";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        getEvents: () => dispatch(getEvents()),
        createAlertSetEvent: (eventId: number, marketCount: number) => dispatch(createAlertSetEvent(eventId, marketCount)),
        createAlertSetView: (newView: CreateAlertCurrentViewTypes) => dispatch(createAlertSetView(newView)),
        createAlertSetPageTitle: (title: string) => dispatch(createAlertSetPageTitle(title)),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type SelectEventComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SelectEventComponent: React.FC<SelectEventComponentProps> = (props) => {
    const {createAlertState, getEvents, createAlertSetEvent, createAlertSetView, createAlertSetPageTitle} = props;
    const theme = useTheme();

    const classes = createAlertCommonStyles();

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    const listMarkets = (eventId: number, marketCount: number, eventName: string) => {
        createAlertSetEvent(eventId, marketCount);
        createAlertSetView(CreateAlertCurrentViewTypes.SELECT_MARKET);
        createAlertSetPageTitle(eventName);
    }

    const cellRenderer = (props: any) => {
        const {index, key, style} = props;

        const event = createAlertState.events![index];

        if (event) {
            return (
                <div style={style}
                     key={key}
                     onClick={() => listMarkets(event.event.id, event.marketCount, event.event.name)}
                >
                    <Card elevation={0}
                          className={classes.card}
                    >
                        <Avatar variant="rounded"
                                className={classes.avatar}>
                            {event.event.name.slice(0, 1)}
                        </Avatar>
                        <div className={classes.cardContents}>
                            <div className={classes.cardTitle}>
                                {event.event.name}
                            </div>
                            <div className={classes.cardData}>
                                {event.marketCount} Market{event.marketCount !== 1 ? "s" : ""} Available
                            </div>
                        </div>
                    </Card>
                </div>
            );
        } else {
            return null;
        }
    };

    if (createAlertState.isLoading) {
        return (
            <LoadingSpinner className={classes.loadingSpinner}
                            color={theme.palette.primary.main}/>
        );
    } else {
        return (
            <Motion key={CreateAlertCurrentViewTypes.SELECT_EVENT}
                    defaultStyle={{opacity: 0}}
                    style={{opacity: spring(1)}}>
                {interpolatedStyle =>
                    <AutoSizer>
                        {({height, width}) => (
                            <List
                                style={{opacity: interpolatedStyle.opacity}}
                                rowRenderer={cellRenderer}
                                rowCount={createAlertState.events!.length}
                                rowHeight={70}
                                height={height}
                                width={width}
                                overscanRowCount={10}
                            />
                        )}
                    </AutoSizer>
                }
            </Motion>
        )
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectEventComponent);
