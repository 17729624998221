import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../redux";
import {CreateAlertCurrentViewTypes, CreateAlertEventActionTypes} from "../../redux/create_alert/types";
import {
    createAlertSetMarket,
    createAlertSetPageTitle,
    createAlertSetView,
    getMarkets
} from "../../redux/create_alert/actions";
import React, {useEffect} from "react";
import {Avatar, Card, GridList, GridListTile, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import {Motion, spring} from "react-motion";
import NumberFormat from "react-number-format";
import createAlertCommonStyles from "./CommonStyles";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        getMarkets: () => dispatch(getMarkets()),
        createAlertSetMarket: (marketId: string) => dispatch(createAlertSetMarket(marketId)),
        createAlertSetView: (newView: CreateAlertCurrentViewTypes) => dispatch(createAlertSetView(newView)),
        createAlertSetPageTitle: (title: string) => dispatch(createAlertSetPageTitle(title)),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type SelectMarketComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SelectMarketComponent: React.FC<SelectMarketComponentProps> = (props) => {
    const {createAlertState, getMarkets, createAlertSetMarket, createAlertSetView, createAlertSetPageTitle} = props;
    const theme = useTheme();

    const classes = createAlertCommonStyles();

    useEffect(() => {
        getMarkets();
    }, [getMarkets]);

    const listAllRunnerOdds = (marketId: string, marketName: string) => {
        createAlertSetMarket(marketId);
        createAlertSetView(CreateAlertCurrentViewTypes.ALL_RUNNER_ODDS)
        createAlertSetPageTitle(marketName);
    }

    if (createAlertState.isLoading) {
        return (
            <LoadingSpinner className={classes.loadingSpinner}
                            color={theme.palette.primary.main}/>
        );
    } else {
        return (
            <Motion key={CreateAlertCurrentViewTypes.SELECT_MARKET}
                    defaultStyle={{opacity: 0}}
                    style={{opacity: spring(1)}}>
                {interpolatedStyle =>
                    <GridList
                        style={{opacity: interpolatedStyle.opacity}}
                        cellHeight='auto'
                        cols={1}
                        spacing={10}>
                        {createAlertState.markets!.map((market, i) => (
                            <GridListTile key={i}
                                          onClick={() => listAllRunnerOdds(market.marketId, market.marketName)}>
                                <Card key={i}
                                      elevation={0}
                                      className={classes.card}
                                >
                                    <Avatar variant="rounded"
                                            className={classes.avatar}>
                                        {market.marketName.slice(0, 1)}
                                    </Avatar>
                                    <div className={classes.cardContents}>
                                        <div className={classes.cardTitle}>
                                            {market.marketName}
                                        </div>
                                        <div className={classes.cardData}>
                                            <NumberFormat value={market.totalMatched}
                                                          displayType={'text'}
                                                          thousandSeparator={true}
                                                          prefix={'£'}/> matched
                                        </div>
                                    </div>
                                </Card>
                            </GridListTile>
                        ))}
                    </GridList>
                }
            </Motion>
        )
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectMarketComponent);
