import React from "react";
import {Card, fade, IconButton, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RootState} from "../redux";
import {connect} from "react-redux";
import {CreateAlertEventActionTypes} from "../redux/create_alert/types";
import {ThunkDispatch} from "redux-thunk";
import {createAlertPreviousView} from "../redux/create_alert/actions";
import {Motion, spring} from "react-motion";
import {ArrowBack} from "@material-ui/icons";
import {isMobile} from "react-device-detect";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        createAlertPreviousView: () => dispatch(createAlertPreviousView()),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

interface CreateAlertListProps {
    children: React.ReactNode,
}

type CreateAlertListComponentProps =
    CreateAlertListProps &
    ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>

const useStyles = makeStyles(theme => ({
    createAlertParent: {
        height: '100%',
        maxHeight: `${isMobile ? 'calc(90vh - 115px)' : 'calc(100vh - 175px)'}`,
        paddingBottom: theme.spacing(4)
    },
    createAlertToolbar: {
        display: 'flex',
        backgroundColor: fade(theme.palette.common.black, 0.2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    backArrow: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.primary.main,
        borderRadius: '0',
    },
    pagesParent: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    toolbarTitle: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        flexGrow: 1,
        color: fade(theme.palette.common.white, 0.8),
        fontFamily: 'Raleway',
        fontSize: '18px',
        fontWeight: 800,
        paddingLeft: theme.spacing(1.5),
        textTransform: 'uppercase',
    },
}));

const CreateAlertList: React.FC<CreateAlertListComponentProps> = (props) => {
    const {createAlertState, createAlertPreviousView} = props;

    const classes = useStyles();

    const previousButtonEnabled = () => {
        return createAlertState.allViews!.length === 1;
    }

    return (
        <Motion defaultStyle={{opacity: 0}}
                style={{opacity: spring(1)}}>
            {interpolatedStyle =>
                <div className={classes.createAlertParent}>
                    <Card variant={"outlined"}
                          style={{opacity: interpolatedStyle.opacity}}
                          className={classes.createAlertToolbar}>
                        <Typography
                            className={classes.toolbarTitle}
                        >
                            {createAlertState.title![createAlertState.title!.length - 1]}
                        </Typography>
                        <IconButton className={classes.backArrow}
                                    onClick={() => createAlertPreviousView()}
                                    disabled={previousButtonEnabled()}
                                    disableRipple>
                            <ArrowBack/>
                        </IconButton>
                    </Card>
                    <div className={classes.pagesParent}
                         style={{opacity: interpolatedStyle.opacity}}>
                        {props.children}
                    </div>
                </div>
            }
        </Motion>
    )
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateAlertList);