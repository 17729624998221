import {NotificationEventActionTypes, NotificationsState, NotificationTypes} from "./types";

const initialState: NotificationsState = {
    notifications: []
};

export function notificationsReducer(
    state = initialState,
    action: NotificationEventActionTypes
): NotificationsState {
    switch (action.type) {
        case NotificationTypes.SUCCESS: {
            return {
                ...state,
                notifications: [...state.notifications, {
                    type: "success",
                    message: action.payload
                }]
            };
        }
        case NotificationTypes.ERROR: {
            return {
                ...state,
                notifications: [...state.notifications, {
                    type: "error",
                    message: action.payload
                }]
            };
        }
        default:
            return state
    }
}