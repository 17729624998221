import {
    CreateAlertCurrentViewTypes,
    CreateAlertEventActionTypes,
    CreateAlertEventModifyViewTypes,
    CreateAlertEventNetworkTypes,
    CreateAlertEventSetterTypes,
    CreateAlertModifyStateTypes,
    CreateAlertState,
    DefaultAlertType
} from "./types";

const initialState: CreateAlertState = {
    allViews: [CreateAlertCurrentViewTypes.SELECT_EVENT_TYPE],
    eventTypes: [],
    events: [],
    markets: [],
    runnerOddsData: {allRunnerOdds: [], totalMatched: 0},
    isLoading: false,
    errors: [],
    title: ["Choose A Category"],
    showCreateAlertDialog: {
        shouldShow: false,
        defaultType: DefaultAlertType.BACK
    },
    // allViews: [CreateAlertCurrentViewTypes.ALL_RUNNER_ODDS],
    // eventTypes: [],
    // events: [],
    // markets: [],
    // runnerOddsData: {allRunnerOdds: [], totalMatched: 0},
    // isLoading: false,
    // errors: [],
    // marketId: "1.128151441",
    // title: ["Choose A Category", "Politics", "US Election"],
    // showCreateAlertDialog: {
    //     shouldShow: false,
    //     defaultType: DefaultAlertType.BACK
    // },
};

export function createAlertReducer(
    state = initialState,
    action: CreateAlertEventActionTypes
): CreateAlertState {
    switch (action.type) {
        case CreateAlertEventNetworkTypes.STARTED: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case CreateAlertEventNetworkTypes.FAILURE: {
            return {
                ...state,
                isLoading: false,
                errors: [...state.errors!, action.meta.error],
            };
        }
        case CreateAlertEventNetworkTypes.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                ...action.payload,
            };
        }
        case CreateAlertEventModifyViewTypes.PREVIOUS_VIEW:
            if (state.allViews!.length > 1) {
                const currentView = state.allViews![state.allViews!.length - 1];

                let newState = {...state, allViews: state.allViews!.slice(0, state.allViews!.length - 1)};

                switch (currentView) {
                    case CreateAlertCurrentViewTypes.SELECT_EVENT:
                        newState.events = [];
                        newState.eventTypeId = 0;
                        break;
                    case CreateAlertCurrentViewTypes.SELECT_MARKET:
                        newState.markets = [];
                        newState.eventId = 0;
                        newState.marketCount = 0;
                        break;
                    case CreateAlertCurrentViewTypes.ALL_RUNNER_ODDS:
                        newState.runnerOddsData = {allRunnerOdds: [], totalMatched: 0};
                        newState.marketId = "";
                        break;
                }

                newState.title = state.title!.slice(0, state.title!.length - 1);

                return {
                    ...newState
                }
            } else {
                return {
                    ...state,
                }
            }
        case CreateAlertEventModifyViewTypes.SET_VIEW:
            return {
                ...state,
                allViews: [...state.allViews!, action.payload]
            }
        case CreateAlertEventSetterTypes.SET_EVENT_TYPE_ID:
            return {
                ...state,
                eventTypeId: action.payload
            }
        case CreateAlertEventSetterTypes.SET_EVENT_ID:
            return {
                ...state,
                eventId: action.payload.eventId,
                marketCount: action.payload.marketCount
            }
        case CreateAlertEventSetterTypes.SET_MARKET:
            return {
                ...state,
                marketId: action.payload
            }
        case CreateAlertEventSetterTypes.SET_PAGE_TITLE:
            return {
                ...state,
                title: [...state.title!, action.payload]
            }
        case CreateAlertModifyStateTypes.RESET_STATE:
            return {
                ...initialState
            }
        case CreateAlertModifyStateTypes.SHOW_DIALOG:
            return {
                ...state,
                showCreateAlertDialog: {
                    shouldShow: action.payload.shouldShow,
                    defaultType: action.payload.defaultType ? action.payload.defaultType : state.showCreateAlertDialog?.defaultType!
                }
            }
        case CreateAlertEventSetterTypes.SET_RUNNER_SELECTION:
            return {
                ...state,
                runnerSelection: action.payload,
            }
        default:
            return state
    }
}