import {isMobile} from "react-device-detect";

export const ALERTS_ENDPOINT = 'v1/api/alerts';
export const BETFAIR_ENDPOINT = 'v1/api/betfair';

const handleError = (err: { response: { status: number; data: { message: any; }; }; message: string | string[]; }) => {
    if (err.response.status === 401) {
        return "Invalid authorization token passed."
    } else if (err.response.status === 500) {
        if (isNotNullOrUndefined(err.response.data) && isNotNullOrUndefined(err.response.data.message)) {
            return err.response.data.message;
        } else {
            return "An internal error occurred. Please contact Zak."
        }
    } else if (err.message === "Network Error") {
        return "Server appears to be offline. Please refresh in a few minutes.";
    } else if (isNotNullOrUndefined(err.response.data.message)) {
        return err.response.data.message;
    } else {
        return err.message;
    }
}

export default handleError;

export const isNotNullOrUndefined = (object: any): boolean => {
    return object !== null && object !== undefined
}

const isPortrait = () => {
    return window.outerHeight >= window.outerWidth;
};

export const isMobilePortrait = () => {
    return isMobile && isPortrait();
};

export const isMobileLandscape = () => {
    return isMobile && !isPortrait();
};
