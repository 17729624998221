import {AlertType} from "../create_alert/types";

export interface ManageAlertsState {
    webSocket?: WebSocket,
    myAlertsList: MyAlert[],
    isLoading?: boolean,
    errors?: string[],
}

export interface MyAlert {
    alertId: string,
    marketId: string,
    marketName: string,
    selectionId: number,
    runnerName: string,
    discordId: number,
    alertType: AlertType,
    alertPrice: number,
}

export enum ManageAlertsEventTypes {
    STARTED = 'MANAGE_ALERTS_EVENT_STARTED',
    FAILURE = 'MANAGE_ALERTS_EVENT_FAILURE',
    SUCCESS = 'MANAGE_ALERTS_EVENT_SUCCESS',
    STORE_ALERTS = 'MANAGE_ALERTS_EVENT_STORE_ALERTS',
    SAVE_WS = 'MANAGE_ALERTS_EVENT_SAVE_WS',
    DELETE_ALERT = 'MANAGE_ALERTS_EVENT_DELETE_ALERT',
}

interface ManageAlertsEventStarted {
    type: ManageAlertsEventTypes.STARTED
}

interface ManageAlertsEventFailure {
    type: ManageAlertsEventTypes.FAILURE
    meta: {
        error: string
    }
}

interface ManageAlertsEventSuccess {
    type: ManageAlertsEventTypes.SUCCESS,
}

interface ManageAlertsEventStoreAlerts {
    type: ManageAlertsEventTypes.STORE_ALERTS,
    payload: MyAlert[]
}

interface ManageAlertsEventSaveWs {
    type: ManageAlertsEventTypes.SAVE_WS,
    payload: WebSocket
}

interface ManageAlertsEventDeleteAlert {
    type: ManageAlertsEventTypes.DELETE_ALERT
}

type ManageAlertsActionTypes =
    ManageAlertsEventStarted
    | ManageAlertsEventFailure
    | ManageAlertsEventSuccess
    | ManageAlertsEventStoreAlerts
    | ManageAlertsEventSaveWs
    | ManageAlertsEventDeleteAlert

export type ManageAlertsEventActionTypes = ManageAlertsActionTypes
