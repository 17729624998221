import {LoginEventActionTypes, LoginEventTypes, LoginState, RelogEventTypes} from "./types";

export const loginStorageKey = 'loginStorageKey';

const initialState: LoginState = {
    apiToken: "",
    userName: "",
    isLoggedIn: false,
    isReloggingIn: false,
    isLoading: false,
    errors: []
};

export function loginReducer(
    state = initialState,
    action: LoginEventActionTypes
): LoginState {
    switch (action.type) {
        case LoginEventTypes.STARTED: {
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false,
            };
        }
        case LoginEventTypes.FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                errors: [...state.errors!, action.meta.error],
            };
        }
        case LoginEventTypes.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                ...action.payload,
            };
        }
        case RelogEventTypes.STARTED: {
            return {
                ...state,
                isReloggingIn: true,
                isLoggedIn: false,
            };
        }
        case RelogEventTypes.FAILURE: {
            return {
                ...state,
                isReloggingIn: false,
                isLoggedIn: false,
                errors: [...state.errors!, action.meta.error],
            };
        }
        case RelogEventTypes.SUCCESS: {
            return {
                ...state,
                isReloggingIn: false,
                isLoggedIn: true,
                ...action.payload,
            };
        }
        default:
            return state
    }
}