import axios from "axios";
import {Dispatch} from "redux";
import {
    CreateAlertCurrentViewTypes,
    CreateAlertEventActionTypes,
    CreateAlertEventModifyViewTypes,
    CreateAlertEventNetworkTypes,
    CreateAlertEventSetterTypes,
    CreateAlertModifyStateTypes,
    CreateAlertState,
    DefaultAlertType,
    Event,
    EventType,
    Market,
    RunnerOdds,
    RunnerOddsData,
} from "./types";
import {notificationError, notificationSuccess} from "../notifications/actions";
import {NotificationEventActionTypes} from "../notifications/types";
import {RootState} from "../index";
import handleError, {ALERTS_ENDPOINT, BETFAIR_ENDPOINT} from "../../util";

export const getEventTypes = () => (dispatch: Dispatch<CreateAlertEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(createAlertEventStarted());

    axios
        .get(`${BETFAIR_ENDPOINT}/list-event-types`, {
            headers: {
                'API-KEY': getState().login.apiToken,
            }
        })
        .then(res => {
            let eventTypes: EventType[] = res.data.data;

            let newState: CreateAlertState = {
                eventTypes: eventTypes
            }

            return dispatch(createAlertEventSuccess(newState));
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));
            dispatch(resetCreateAlertState());

            return dispatch(createAlertEventFailure(err));
        })
};

export const getEvents = () => (dispatch: Dispatch<CreateAlertEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(createAlertEventStarted());

    axios
        .get(`${BETFAIR_ENDPOINT}/list-events/${getState().createAlert.eventTypeId}`, {
            headers: {
                'API-KEY': getState().login.apiToken,
            }
        })
        .then(res => {
            let events: Event[] = res.data.data;

            let newState: CreateAlertState = {
                events: events
            }

            return dispatch(createAlertEventSuccess(newState));
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));
            dispatch(resetCreateAlertState());

            return dispatch(createAlertEventFailure(err));
        })
}

export const getMarkets = () => (dispatch: Dispatch<CreateAlertEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(createAlertEventStarted());

    axios
        .get(`${BETFAIR_ENDPOINT}/markets/${getState().createAlert.eventId}/${getState().createAlert.marketCount}`, {
            headers: {
                'API-KEY': getState().login.apiToken,
            }
        })
        .then(res => {
            let markets: Market[] = res.data.data;

            let newState: CreateAlertState = {
                markets: markets
            }

            return dispatch(createAlertEventSuccess(newState));
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));
            dispatch(resetCreateAlertState());

            return dispatch(createAlertEventFailure(err));
        })
}

export const getAllRunnerOdds = () => (dispatch: Dispatch<CreateAlertEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(createAlertEventStarted());

    axios
        .get(`${BETFAIR_ENDPOINT}/all-runner-odds/${getState().createAlert.marketId}`, {
            headers: {
                'API-KEY': getState().login.apiToken,
            }
        })
        .then(res => {
            let allRunnerOdds: RunnerOddsData = res.data.data;

            let newState: CreateAlertState = {
                runnerOddsData: allRunnerOdds
            }

            return dispatch(createAlertEventSuccess(newState));
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));

            return dispatch(createAlertEventFailure(err));
        })
}

export const createAlert = (oddsAmount: number, alertType: string) => (dispatch: Dispatch<CreateAlertEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    let createAlertState = getState().createAlert;

    let createAlertTitles = createAlertState.title!;

    let formattedMarketName = `${createAlertTitles[createAlertTitles.length - 3]} | ${createAlertTitles[createAlertTitles.length - 2]} | ${createAlertTitles[createAlertTitles.length - 1]}`

    axios
        .post(`${ALERTS_ENDPOINT}/create-alert`, {
                marketId: createAlertState.marketId,
                marketName: formattedMarketName,
                selectionId: createAlertState.runnerSelection?.selectionId,
                runnerName: createAlertState.runnerSelection?.runnerName,
                alertType: alertType,
                alertPrice: oddsAmount,
            },
            {
                headers: {
                    'API-KEY': getState().login.apiToken,
                }
            })
        .then(res => {
            let successMessage = res.data.data;

            return dispatch(notificationSuccess(successMessage));
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));

            return dispatch(createAlertEventFailure(err));
        })
}

const createAlertEventStarted = (): CreateAlertEventActionTypes => ({
    type: CreateAlertEventNetworkTypes.STARTED,
});

const createAlertEventSuccess = (createAlertState: CreateAlertState): CreateAlertEventActionTypes => ({
    type: CreateAlertEventNetworkTypes.SUCCESS,
    payload: createAlertState
});

const createAlertEventFailure = (err: string): CreateAlertEventActionTypes => ({
    type: CreateAlertEventNetworkTypes.FAILURE,
    meta: {
        error: err
    }
});

const resetCreateAlertState = (): CreateAlertEventActionTypes => ({
    type: CreateAlertModifyStateTypes.RESET_STATE
});

export const createAlertPreviousView = (): CreateAlertEventActionTypes => ({
    type: CreateAlertEventModifyViewTypes.PREVIOUS_VIEW,
});

export const createAlertSetView = (newView: CreateAlertCurrentViewTypes): CreateAlertEventActionTypes => ({
    type: CreateAlertEventModifyViewTypes.SET_VIEW,
    payload: newView
});

export const createAlertSetEventType = (eventTypeId: number): CreateAlertEventActionTypes => ({
    type: CreateAlertEventSetterTypes.SET_EVENT_TYPE_ID,
    payload: eventTypeId
});

export const createAlertSetEvent = (eventId: number, marketCount: number): CreateAlertEventActionTypes => ({
    type: CreateAlertEventSetterTypes.SET_EVENT_ID,
    payload: {
        eventId: eventId,
        marketCount: marketCount
    }
});

export const createAlertSetMarket = (marketId: string): CreateAlertEventActionTypes => ({
    type: CreateAlertEventSetterTypes.SET_MARKET,
    payload: marketId
});

export const createAlertSetPageTitle = (title: string): CreateAlertEventActionTypes => ({
    type: CreateAlertEventSetterTypes.SET_PAGE_TITLE,
    payload: title
})

export const showCreateAlertDialog = (shouldShow: boolean, defaultType?: DefaultAlertType): CreateAlertEventActionTypes => ({
    type: CreateAlertModifyStateTypes.SHOW_DIALOG,
    payload: {
        shouldShow: shouldShow,
        defaultType: defaultType
    }
})

export const setRunnerSelection = (runner: RunnerOdds): CreateAlertEventActionTypes => ({
    type: CreateAlertEventSetterTypes.SET_RUNNER_SELECTION,
    payload: runner
})