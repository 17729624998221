import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./redux/store";
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";
import "./App.css";
import 'typeface-roboto';
import 'raleway-webfont';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#1a1a1a',
        },
        primary: {
            main: '#f8b332',
        },
        secondary: {
            main: "#342406",
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <App/>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
