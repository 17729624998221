import React from "react";
import {fade, makeStyles} from "@material-ui/core/styles";
import {Card, Grid, Paper, Typography} from "@material-ui/core";
import {spring, StaggeredMotion} from "react-motion";
import CreateAlertPage from "./CreateAlertPage";
import SelectEventTypeComponent from "./create_alert/SelectEventTypeComponent";
import {CreateAlertCurrentViewTypes} from "../redux/create_alert/types";
import CreateAlertList from "./CreateAlertList";
import SelectEventComponent from "./create_alert/SelectEventComponent";
import SelectMarketComponent from "./create_alert/SelectMarketComponent";
import ListAllRunnerOddsComponent from "./create_alert/ListAllRunnerOddsComponent";
import ManageAlertsList from "./ManageAlertsList";
import {isMobile} from "react-device-detect";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        backgroundColor: '#161616',
    },
    cardTitle: {
        color: theme.palette.primary.main,
        textAlign: 'left',
        fontFamily: 'Raleway',
        fontSize: '24px',
        fontWeight: 800,
        paddingTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(3),
    },
    alertsPaper: {
        height: '100%',
        margin: theme.spacing(2),
        backgroundColor: 'inherit',
    },
    rotateMessage: {
        display: 'flex',
        justifyContent: 'center',
        color: fade(theme.palette.common.white, 0.8),
        fontSize: '18px'
    },
    fullHeight: {
        height: '90vh',
    }
}));

const HomeComponent: React.FC = () => {
    const classes = useStyles();

    return (
        <StaggeredMotion
            defaultStyles={[
                {move_amount: -100, opacity: 0},
                {move_amount: -100, opacity: 0},
                {move_amount: -100, opacity: 0}
            ]}
            styles={prevInterpolatedStyles => prevInterpolatedStyles!.map((_, i) => {
                return i === 0
                    ? {
                        move_amount: spring(1, {stiffness: 80, damping: 20}),
                        opacity: spring(1, {stiffness: 90, damping: 10})
                    }
                    : {
                        move_amount: spring(prevInterpolatedStyles![i - 1].move_amount, {
                            stiffness: 100,
                            damping: 20
                        }),
                        opacity: spring(prevInterpolatedStyles![i - 1].opacity, {
                            stiffness: 60,
                            damping: 5
                        })
                    };
            })}
        >
            {(cardStyles: any[]) =>
                <Grid className={classes.root}
                      container
                      spacing={4}>
                    <Grid item
                          xs={isMobile ? 12 : 8}>
                        <Card className={`${classes.card} ${isMobile ? classes.fullHeight : null}`}
                              variant={"outlined"}
                        >
                            <div
                            >
                                <Typography
                                    noWrap
                                    className={classes.cardTitle}
                                    style={cardStyles[0] && {
                                        transform: `translateY(${cardStyles[0].move_amount * 1.5}px)`,
                                        opacity: `${cardStyles[0].opacity}`
                                    }}
                                >
                                    ACTIVE ALERTS
                                </Typography>
                            </div>
                            <Paper elevation={0}
                                   className={classes.alertsPaper}>
                                <ManageAlertsList/>
                            </Paper>
                        </Card>
                    </Grid>
                    <Grid item
                          xs={isMobile ? 12 : 4}>
                        <Card className={`${classes.card} ${isMobile ? classes.fullHeight : null}`}
                              variant={"outlined"}
                        >
                            <div
                            >
                                <Typography
                                    noWrap
                                    className={classes.cardTitle}
                                    style={cardStyles[0] && {
                                        transform: `translateY(${cardStyles[0].move_amount * 1.5}px)`,
                                        opacity: `${cardStyles[0].opacity}`
                                    }}
                                >
                                    CREATE ALERT
                                </Typography>
                            </div>
                            <Paper elevation={0}
                                   className={classes.alertsPaper}>
                                <CreateAlertList>
                                    <CreateAlertPage view={CreateAlertCurrentViewTypes.SELECT_EVENT_TYPE}
                                                     component={SelectEventTypeComponent}/>
                                    <CreateAlertPage view={CreateAlertCurrentViewTypes.SELECT_EVENT}
                                                     component={SelectEventComponent}/>
                                    <CreateAlertPage view={CreateAlertCurrentViewTypes.SELECT_MARKET}
                                                     component={SelectMarketComponent}/>
                                    <CreateAlertPage view={CreateAlertCurrentViewTypes.ALL_RUNNER_ODDS}
                                                     component={ListAllRunnerOddsComponent}/>
                                </CreateAlertList>
                            </Paper>
                        </Card>
                    </Grid>
                </Grid>
            }
        </StaggeredMotion>
    )
}

export default HomeComponent;
