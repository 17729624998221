import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../redux";
import {
    AvailableToBack,
    AvailableToLay,
    CreateAlertCurrentViewTypes,
    CreateAlertEventActionTypes,
    DefaultAlertType,
    RunnerOdds
} from "../../redux/create_alert/types";
import {getAllRunnerOdds, setRunnerSelection, showCreateAlertDialog} from "../../redux/create_alert/actions";
import React, {useEffect} from "react";
import {Card, fade, Paper, Typography, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LoadingSpinner from "../LoadingSpinner";
import {Motion, spring} from "react-motion";
import {connect} from "react-redux";
import NumberFormat from "react-number-format";
import {AutoSizer, List} from "react-virtualized";
import CreateAlertDialog from "./CreateAlertDialog";
import {isNotNullOrUndefined} from "../../util";
import {isMobile} from "react-device-detect";

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, CreateAlertEventActionTypes>) => {
    return {
        getAllRunnerOdds: () => dispatch(getAllRunnerOdds()),
        showCreateAlertDialog: (shouldShow: boolean, defaultType: DefaultAlertType) => dispatch(showCreateAlertDialog(shouldShow, defaultType)),
        setRunnerSelection: (runner: RunnerOdds) => dispatch(setRunnerSelection(runner)),
    }
};

const mapStateToProps = (state: RootState) => ({
    createAlertState: state.createAlert,
});

type ListAllRunnerOddsComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        overflow: 'hidden',
        paddingBottom: theme.spacing(4)
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        backgroundColor: 'inherit',
        textAlign: 'center'
    },
    cardTitle: {
        flex: 0.4,
        color: theme.palette.primary.main,
        fontFamily: 'Raleway',
        fontSize: `${isMobile ? '13px' : '16px'}`,
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 800,
        overflowWrap: 'anywhere',
        marginRight: '8px'
    },
    cardContents: {
        flex: 0.6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.common.black,
    },
    loadingSpinner: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    totalMatched: {
        fontSize: '16px',
        color: fade(theme.palette.common.white, 0.8),
        marginLeft: theme.spacing(1),
        textAlign: 'left',
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    availableToBack: {
        display: 'flex',
        backgroundColor: '#a6d7ff',
        fontWeight: 500,
        flexFlow: 'column',
        minWidth: `${isMobile ? '108px' : '130px'}`,
        width: 'auto',
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.25),
        borderRadius: '4px',
    },
    availableToBackClickable: {
        '&:hover': {
            backgroundColor: fade('#a6d7ff', 0.6),
            cursor: 'pointer',
        },
    },
    availableToLay: {
        display: 'flex',
        backgroundColor: '#fac9d1',
        fontWeight: 500,
        flexFlow: 'column',
        minWidth: `${isMobile ? '108px' : '130px'}`,
        width: 'auto',
        padding: theme.spacing(0.25),
        borderRadius: '4px',
    },
    availableToLayClickable: {
        '&:hover': {
            backgroundColor: fade('#fac9d1', 0.6),
            cursor: 'pointer',
        },
    },
    odds: {
        fontSize: `${isMobile ? '15px' : '17px'}`,
        marginBottom: '-3px'
    },
    poolSize: {
        fontSize: '13px',
    },
    alertsPaper: {
        height: 'calc(100vh - 250px)',
        margin: theme.spacing(2),
        backgroundColor: 'inherit',
    },
    alternateItem: {
        display: 'flex',
        color: '#5f5f5f',
        fontSize: '18px',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const ListAllRunnerOddsComponent: React.FC<ListAllRunnerOddsComponentProps> = (props) => {
    const {createAlertState, getAllRunnerOdds, showCreateAlertDialog, setRunnerSelection} = props;
    const theme = useTheme();

    const classes = useStyles();

    useEffect(() => {
        getAllRunnerOdds();

        const intervalID = setInterval(getAllRunnerOdds, 5000);

        return () => {
            clearInterval(intervalID)
        }
    }, [getAllRunnerOdds]);

    const getOddsPrice = (oddsDetails: AvailableToBack | AvailableToLay | undefined): string => {
        // @ts-ignore
        if (isNotNullOrUndefined(oddsDetails) && isNotNullOrUndefined(oddsDetails.price)) {
            // @ts-ignore
            return `${oddsDetails.price} (${((1 / oddsDetails.price) * 100).toFixed(2)}%)`
        } else {
            return "N/A"
        }
    }

    const getPoolSize = (oddsDetails: AvailableToBack | AvailableToLay | undefined): JSX.Element | string => {
        // @ts-ignore
        if (isNotNullOrUndefined(oddsDetails) && isNotNullOrUndefined(oddsDetails.size)) {
            // @ts-ignore
            return <NumberFormat value={oddsDetails.size}
                                 className={classes.poolSize}
                                 displayType={'text'}
                                 thousandSeparator={true}
                                 prefix={'£'}/>
        } else {
            return "N/A"
        }
    }

    const isClickable = (runner: RunnerOdds, defaultType: DefaultAlertType): boolean => {
        switch (defaultType) {
            case DefaultAlertType.BACK:
                return isNotNullOrUndefined(runner.availableToBack) && isNotNullOrUndefined(runner.availableToBack.price);
            case DefaultAlertType.LAY:
                return isNotNullOrUndefined(runner.availableToLay) && isNotNullOrUndefined(runner.availableToLay.price);
        }
    }

    const handleClickOdds = (runner: RunnerOdds, defaultType: DefaultAlertType) => {
        showCreateAlertDialog(true, defaultType);
        setRunnerSelection(runner);
    }

    const cellRenderer = (props: any) => {
        const {index, key, style} = props;

        const runner = createAlertState.runnerOddsData!.allRunnerOdds[index];

        if (runner) {
            return (
                <div style={style}
                     key={key}
                >
                    <Card className={classes.card}
                          elevation={0}
                    >
                        <div className={classes.cardTitle}>
                            {runner.runnerName}
                        </div>
                        <div className={classes.cardContents}>
                            <div className={`${classes.availableToBack} ${isClickable(runner, DefaultAlertType.BACK) ? classes.availableToBackClickable : null}`}
                                 onClick={() => isClickable(runner, DefaultAlertType.BACK) ? handleClickOdds(runner, DefaultAlertType.BACK) : null}
                            >
                                <span className={classes.odds}>{getOddsPrice(runner.availableToBack)}</span>
                                <span className={classes.poolSize}>
                                    {getPoolSize(runner.availableToBack)}
                                </span>
                            </div>
                            <div className={`${classes.availableToLay} ${isClickable(runner, DefaultAlertType.LAY) ? classes.availableToLayClickable : null}`}
                                 onClick={() => isClickable(runner, DefaultAlertType.LAY) ? handleClickOdds(runner, DefaultAlertType.LAY) : null}
                            >
                                <span className={classes.odds}>{getOddsPrice(runner.availableToLay)}</span>
                                <span className={classes.poolSize}>
                                    {getPoolSize(runner.availableToLay)}
                                </span>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        } else {
            return null;
        }
    };

    if (createAlertState.runnerOddsData) {
        return (
            <Motion key={CreateAlertCurrentViewTypes.SELECT_MARKET}
                    defaultStyle={{opacity: 0}}
                    style={{opacity: spring(1)}}>
                {interpolatedStyle =>
                    <div className={classes.root}>
                        <CreateAlertDialog/>

                        <NumberFormat value={createAlertState.runnerOddsData!.totalMatched}
                                      className={classes.totalMatched}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'Matched - £'}/>

                        <AutoSizer style={{paddingTop: '8px'}}>
                            {({height, width}) => (
                                <List
                                    style={{opacity: interpolatedStyle.opacity}}
                                    rowRenderer={cellRenderer}
                                    rowCount={createAlertState.runnerOddsData!.allRunnerOdds.length}
                                    rowHeight={75}
                                    height={height}
                                    width={width}
                                    overscanRowCount={10}
                                />
                            )}
                        </AutoSizer>
                    </div>
                }
            </Motion>
        )
    } else if (createAlertState.isLoading) {
        return (
            <LoadingSpinner className={classes.loadingSpinner}
                            color={theme.palette.primary.main}/>
        );
    } else {
        return (
            <Paper elevation={0}
                   className={classes.alertsPaper}>
                <Typography className={classes.alternateItem}
                >No data was found.</Typography>
            </Paper>
        )
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListAllRunnerOddsComponent);
