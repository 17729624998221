import React from "react";
import {ManageAlertsEventActionTypes, MyAlert} from "../redux/manage_alerts/types";
import {makeStyles} from "@material-ui/core/styles";
import {Card, fade, IconButton, Tooltip} from "@material-ui/core";
import {AlertType} from "../redux/create_alert/types";
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../redux";
import {deleteAlert} from "../redux/manage_alerts/actions";
import {isMobile} from "react-device-detect";

interface MyAlertCardProps {
    alert: MyAlert
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, ManageAlertsEventActionTypes>) => {
    return {
        deleteAlert: (alert_id: string) => dispatch(deleteAlert(alert_id)),
    }
};

type MyAlertCardComponentProps = MyAlertCardProps & ReturnType<typeof mapDispatchToProps>;

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        alignItems: 'center',
        height: '90px',
        backgroundColor: fade(theme.palette.common.black, 0.2),
        padding: theme.spacing(2)
    },
    cardContents: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        fontFamily: 'Raleway',
    },
    marketInfo: {
        flex: 0.9
    },
    runnerName: {
        color: theme.palette.primary.main,
        fontSize: `${isMobile ? '16px' : '18px'}`,
        fontWeight: 800,
    },
    marketName: {
        color: fade(theme.palette.common.white, 0.8),
        fontSize: `${isMobile ? '10px' : '16px'}`,
        fontStyle: 'italic'
    },
    alertType: {
        flex: `${isMobile ? 0.4 : 0.3}`,
        fontSize: `${isMobile ? '13px' : '20px'}`,
        textAlign: 'left',
        fontWeight: 800,
        textTransform: 'uppercase',
        color: fade(theme.palette.common.white, 0.7),
    },
    oddsPrice: {
        flex: `${isMobile ? 0.2 : 0.2}`,
        fontSize: `${isMobile ? '22px' : '38px'}`,
        color: theme.palette.primary.main,
        fontWeight: 800,
        marginBottom: '8px'
    },
    cancelIcon: {
        color: fade('#f83232', 0.8),
    },
    backColor: {
        color: '#a6d7ff',
    },
    layColor: {
        color: '#fac9d1',
    }
}));

const MyAlertCard: React.FC<MyAlertCardComponentProps> = (props) => {
    const {alert, deleteAlert} = props;

    const classes = useStyles();

    const getAlertTypeText = (alertType: AlertType): JSX.Element => {
        switch (alertType) {
            case AlertType.HigherThanBackPrice:
                return (
                    <>
                        <span className={classes.backColor}>Back</span> higher than
                    </>
                )
            case AlertType.LowerThanBackPrice:
                return (
                    <>
                        <span className={classes.backColor}>Back</span> lower than
                    </>
                )
            case AlertType.HigherThanLayPrice:
                return (
                    <>
                        <span className={classes.layColor}>Lay</span> higher than
                    </>
                )
            case AlertType.LowerThanLayPrice:
                return (
                    <>
                        <span className={classes.layColor}>Lay</span> lower than
                    </>
                )
            case AlertType.LowerThanLastPriceTraded:
                return (
                    <>
                        Last price traded lower than
                    </>
                )
            case AlertType.HigherThanLastPriceTraded:
                return (
                    <>
                        Last price traded higher than
                    </>
                )
        }
    };

    return (
        <Card elevation={0}
              variant={"outlined"}
              className={classes.card}
        >
            <div className={classes.cardContents}>
                <div className={classes.marketInfo}>
                    <div className={classes.runnerName}>
                        {alert.runnerName}
                    </div>
                    <div className={classes.marketName}>
                        {alert.marketName}
                    </div>
                </div>
                <div className={classes.alertType}>
                    {getAlertTypeText(alert.alertType)}
                </div>
                <div className={classes.oddsPrice}>
                    {alert.alertPrice}
                </div>
                <Tooltip title="Delete Alert">
                    <IconButton className={classes.cancelIcon}
                                size={isMobile ? "small" : "medium"}
                                onClick={() => deleteAlert(alert.alertId)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </Card>
    );
};

export default connect(
    null,
    mapDispatchToProps,
)(MyAlertCard);