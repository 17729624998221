import React, {useEffect} from "react";
import {RootState} from "../redux";
import {connect} from "react-redux";
import {useSnackbar} from 'notistack';

const mapStateToProps = (state: RootState) => ({
    notificationState: state.notifications,
});

type NotificationDisplayerProps = ReturnType<typeof mapStateToProps>;

const NotificationDisplayer: React.FC<NotificationDisplayerProps> = (props) => {
    const {notificationState} = props;

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const latestAlert = notificationState.notifications[notificationState.notifications.length - 1];

        const alertDataString: string = JSON.stringify(latestAlert);

        if (latestAlert) {
            enqueueSnackbar(alertDataString, {key: notificationState.notifications.length});
        }
    }, [notificationState, enqueueSnackbar]);

    return null;
}

export default connect(
    mapStateToProps,
)(NotificationDisplayer)
