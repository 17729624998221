import {Dispatch} from "redux";
import {ManageAlertsEventActionTypes, ManageAlertsEventTypes, MyAlert} from "./types";
import {NotificationEventActionTypes} from "../notifications/types";
import {notificationError, notificationSuccess} from "../notifications/actions";
import {RootState} from "../index";
import axios from "axios";
import handleError, {ALERTS_ENDPOINT} from "../../util";

export const connectManageAlerts = () => (dispatch: Dispatch<any | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(manageAlertsEventStarted());

    //close current websocket if it is open
    let currentWs = getState().manageAlerts.webSocket;

    if (currentWs !== undefined && currentWs.OPEN) {
        currentWs.close();
    }

    let ws = new WebSocket(`wss://${window.location.hostname}/v1/api/alerts/manage-alerts?api_key=${getState().login.apiToken}`);

    ws.onopen = () => {
        dispatch(manageAlertsEventSuccess());
    };

    ws.onclose = () => {
        console.log("Successfully disconnected.");

        setTimeout(() => {
            console.log('Attempting to reconnect to WebSocket.');
            dispatch(connectManageAlerts());
        }, 5000)
    };

    ws.onerror = (e: Event) => {
        console.log(e);

        dispatch(manageAlertsEventFailure(`${e}`));

        dispatch(notificationError("Failed to connect to websocket."));
    };

    ws.onmessage = (message: MessageEvent) => {
        let myAlerts: MyAlert[] = JSON.parse(message.data);

        dispatch(storeAlerts(myAlerts));
    };

    dispatch(saveWs(ws));
};

export const deleteAlert = (alert_id: string) => (dispatch: Dispatch<ManageAlertsEventActionTypes | NotificationEventActionTypes>, getState: () => RootState) => {
    dispatch(manageAlertsEventStarted());

    axios
        .delete(`${ALERTS_ENDPOINT}/delete-alert/${alert_id}`, {
            headers: {
                'API-KEY': getState().login.apiToken,
            }
        })
        .then(res => {
            let successMessage = res.data.data;

            dispatch(notificationSuccess(successMessage));

            return dispatch(manageAlertsEventSuccess());
        })
        .catch(err => {
            err = handleError(err);

            dispatch(notificationError(err));

            return dispatch(manageAlertsEventFailure(err));
        })
}

const saveWs = (ws: WebSocket): ManageAlertsEventActionTypes => ({
    type: ManageAlertsEventTypes.SAVE_WS,
    payload: ws
})

const manageAlertsEventStarted = (): ManageAlertsEventActionTypes => ({
    type: ManageAlertsEventTypes.STARTED,
});

const manageAlertsEventSuccess = (): ManageAlertsEventActionTypes => ({
    type: ManageAlertsEventTypes.SUCCESS,
});

const manageAlertsEventFailure = (err: string): ManageAlertsEventActionTypes => ({
    type: ManageAlertsEventTypes.FAILURE,
    meta: {
        error: err
    }
});

const storeAlerts = (alerts: MyAlert[]): ManageAlertsEventActionTypes => ({
    type: ManageAlertsEventTypes.STORE_ALERTS,
    payload: alerts
})